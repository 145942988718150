import { Overlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, WritableSignal, effect, signal } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslocoModule } from '@ngneat/transloco';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { Process } from 'app/api';
import { AlertService } from 'app/core/alert/alert.service';
import { DialogBuilderComponent } from 'app/core/dialogBuilder/dialog-builder.component';
import { OrganisationFilterService } from 'app/layout/common/organisation-filter/organisation-filter.service';
import { takeUntil } from 'rxjs';
import { ProcessService } from '../process.service';
import { BpManagementFormService } from '../bp-management-form.service';

@Component({
    selector: 'create-process-dialog',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormlyModule,
        FormlyMaterialModule,
        TranslocoModule,
        MatStepperModule,
        MatButtonModule,
        MatIconModule,
    ],
    templateUrl: './create-process-dialog.component.html',
    styleUrl: './create-process-dialog.component.scss',
    providers: [
        {
            provide: MAT_SELECT_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.reposition(),
            deps: [Overlay],
        },
    ],
})
export class CreateProcessDialogComponent extends DialogBuilderComponent<Process> {
    form = new FormGroup({});
    model?: Partial<Process>;
    fields1: WritableSignal<FormlyFieldConfig[]> = signal(null);
    fields2: WritableSignal<FormlyFieldConfig[]> = signal(null);

    isSubProcess = false;

    constructor(
        private processFormService: BpManagementFormService,
        private alertService: AlertService,
        private orgFilterService: OrganisationFilterService,
        private _processNewService: ProcessService
    ) {
        super();

        effect(
            () => {
                const code = this.executeActionSignal();

                if (code === 'create') {
                    this.createProcess();
                }

                this.executeActionSignal.set(null);
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    override ngOnInit(): void {
        super.ngOnInit();

        this.setUpFields();
    }

    private setUpFields(): void {
        if (this.componentModel) {
            this.isSubProcess = true;
            this.setUpFieldsForSubprocess();
        } else {
            this.model = {
                version: '1',
            };
            this.setUpFieldsForTopProcess();
        }
    }

    private setUpFieldsForTopProcess() {
        this.fields1.set([
            this.processFormService.titleFieldDef,
            this.processFormService.descriptionFieldDef,
        ]);
        this.fields2.set([
            {
                fieldGroupClassName: 'flex flex-row gap-2 flex-wrap',
                fieldGroup: [
                    this.processFormService.wbsFieldDef,
                    this.processFormService.authGroupFieldDef,
                ],
            },
            {
                fieldGroupClassName: 'flex flex-row gap-2 flex-wrap',
                fieldGroup: [
                    this.processFormService.areaFieldDef,
                    this.processFormService.ownerFieldDef,
                ],
            },
        ]);
    }
    private setUpFieldsForSubprocess() {
        this.model = this.componentModel;
        this.fields1.set([
            this.processFormService.titleFieldDef,
            this.processFormService.descriptionFieldDef,
        ]);
        this.fields2.set([
            {
                fieldGroupClassName: 'flex flex-row gap-2 flex-wrap',
                fieldGroup: [
                    {
                        ...this.processFormService.wbsFieldDef,
                        props: { ...this.processFormService.wbsFieldDef.props, disabled: true },
                    },
                    {
                        ...this.processFormService.authGroupFieldDef,
                        props: {
                            ...this.processFormService.authGroupFieldDef.props,
                            disabled: true,
                        },
                    },
                ],
            },
            {
                fieldGroupClassName: 'flex flex-row gap-2 flex-wrap',
                fieldGroup: [
                    {
                        ...this.processFormService.areaFieldDef,
                        props: {
                            ...this.processFormService.areaFieldDef.props,
                            disabled: true,
                        },
                    },
                    {
                        ...this.processFormService.ownerFieldDef,
                        props: {
                            ...this.processFormService.ownerFieldDef.props,
                            disabled: true,
                        },
                    },
                ],
            },
        ]);
    }
    create(): void {
        this.createProcess();
    }

    private createProcess() {
        if (this.form.status !== 'VALID') {
            this.alertService.errorAlert('bp', 'processNotValid', 'error');
            return;
        }

        if (this.componentModel) {
            this.createSubprocess();
        } else {
            this.createTopProcess();
        }
    }

    private createTopProcess() {
        const topProcess: Process = {
            ...this.form.value,
            status: 'D',
            organisation: this.orgFilterService.selectedOrganisation(),
            type: 'process',
            version: '1',
        };

        this._processNewService
            .createNewProcess(topProcess)
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this.closeDialog();
                this.alertService.successAlert(
                    'bp',
                    'newProcessCreateSuccessfulMessage',
                    'created'
                );
            });
    }
    createSubprocess() {
        const subprocess: Process = {
            ...this.componentModel,
            ...this.form.value,
        };

        if (!subprocess.type && subprocess.parentProcess) {
            subprocess.type = 'subprocess';
        } else if (!subprocess.type && !subprocess.parentProcess) {
            subprocess.type = 'process';
        }

        this._processNewService
            .createNewProcess(subprocess)
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
                this.closeDialog();
                this.alertService.successAlert(
                    'bp',
                    'newProcessCreateSuccessfulMessage',
                    'created'
                );
            });
    }
}
