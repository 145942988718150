<div *transloco="let t; read: 'bp'">
    <mat-stepper linear [selectedIndex]="isSubProcess ? 1 : 0">
        <mat-step [completed]="true">
            <ng-template matStepLabel>
                {{ t('details') }}
            </ng-template>
            <div class="my-2">
                <h6> {{ t('version') }}: {{ model.version }} </h6>
            </div>
            <formly-form [form]="form" [fields]="fields2()" [model]="model"></formly-form>
            <div class="flex flex-row justify-center">
                <button mat-raised-button color="primary" matStepperNext>
                    {{ t('next') }}
                </button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>
                {{ t('overview') }}
            </ng-template>
            <div class="my-2">
                <h6> {{ t('version') }}: {{ model.version }} </h6>
            </div>
            <div *ngIf="isSubProcess" class="flex flex-row gap-2 justify-center">
                <mat-icon svgIcon="heroicons_outline:information-circle"></mat-icon>
                <span class="my-auto"> {{ t('info') }} </span>
            </div>
            <formly-form [form]="form" [fields]="fields1()" [model]="model"></formly-form>
            <div class="flex flex-row gap-2 justify-center">
                <button mat-button matStepperPrevious>
                    {{ t('back') }}
                </button>
                <button mat-raised-button color="primary" (click)="create()">
                    {{ t('create') }}</button
                >
            </div>
        </mat-step>
    </mat-stepper>
</div>
