import { DestroyRef, Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { NewMDOService } from 'app/services/new_mdo.service';
import { ProcessService } from './process.service';
import { Process } from 'app/api';

@Injectable({
    providedIn: 'root',
})
export class BpManagementFormService {
    private _translocoContent: WritableSignal<Translation> = signal({});
    private _errors: WritableSignal<Translation> = signal({});

    constructor(
        private _translocoService: TranslocoService,
        private _destroyRef: DestroyRef,
        private _processService: ProcessService,
        private _newMdoService: NewMDOService
    ) {
        this._translocoService
            .selectTranslation('bp')
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(content => {
                this._translocoContent.set(content);
                this._errors.set(this._translocoService.translateObject('errors'));
            });
    }

    getWbsField(model: Signal<Process>): FormlyFieldConfig[] {
        if (model().type === 'subprocess') {
            return [
                {
                    key: 'wbsPrefix',
                    className: 'flex-1',
                    type: 'input',
                    props: {
                        label: this._translocoContent()['wbsPrefix'],
                        required: true,
                    },
                    hooks: {
                        onInit: field => {
                            const wbs = model().wbs || '';
                            const lastDotIndex = wbs.lastIndexOf('.');
                            field.formControl?.setValue(wbs.slice(0, lastDotIndex + 1));
                        },
                    },
                },
                {
                    key: 'wbsLastDigit',
                    className: 'flex-1',
                    type: 'input',
                    props: {
                        label: this._translocoContent()['wbsSuffix'],
                        required: true,
                        type: 'number',
                        min: 1,
                    },
                    hooks: {
                        onInit: field => {
                            const wbs = model().wbs || '';
                            const lastDotIndex = wbs.lastIndexOf('.');
                            field.formControl?.setValue(wbs.slice(lastDotIndex + 1));
                        },
                    },
                },
            ];
        }
        return [this.wbsFieldDef];
    }

    get wbsFieldDef(): FormlyFieldConfig {
        return {
            className: 'flex-1',
            type: 'input',
            key: 'wbs',
            props: {
                label: this._translocoContent()['wbs'],
                placeholder: 'ABC-1.1',
                required: true,
            },
            validation: this.getRequiredValidation(this._errors()['required']),
        };
    }

    get versionFieldDef(): FormlyFieldConfig {
        return {
            className: 'flex-1',
            type: 'input',
            key: 'version',
            props: {
                label: this._translocoContent()['version'],
                required: true,
                type: 'number',
                min: 1,
            },
            validation: this.getRequiredValidation(this._errors()['required']),
        };
    }

    get titleFieldDef(): FormlyFieldConfig {
        return {
            className: 'flex-1',
            type: 'input',
            key: 'titel',
            props: {
                label: this._translocoContent()['title'],
                required: true,
            },
            validation: this.getRequiredValidation(this._errors()['required']),
        };
    }

    get descriptionFieldDef(): FormlyFieldConfig {
        return {
            className: '',
            type: 'input',
            key: 'description',
            props: {
                label: this._translocoContent()['description'],
                required: true,
            },
            validation: this.getRequiredValidation(this._errors()['required']),
        };
    }

    get areaFieldDef(): FormlyFieldConfig {
        return {
            className: 'flex-1',
            type: 'md-object-dialog',
            key: 'area',
            props: {
                label: this._translocoContent()['area'],
                required: true,
                valueProp: 'id',
                labelProp: 'title',
                attributes: {
                    selectionType: 'area',
                },
            },
            validation: this.getRequiredValidation(this._errors()['required']),
        };
    }

    get ownerFieldDef(): FormlyFieldConfig {
        return {
            className: 'flex-1',
            type: 'select',
            key: 'ownerid',
            props: {
                label: this._translocoContent()['owner'],
                required: true,
                options: this._newMdoService.usersForSelect,
            },
            validation: this.getRequiredValidation(this._errors()['required']),
        };
    }

    get statusFieldDef(): FormlyFieldConfig {
        return {
            className: 'flex-1',
            type: 'select',
            key: 'status',
            props: {
                label: this._translocoContent()['status'],
                options: this._newMdoService.statuses,
            },
        };
    }

    get authGroupFieldDef(): FormlyFieldConfig {
        return {
            className: 'flex-1',
            type: 'select',
            key: 'auth',
            props: {
                label: this._translocoContent()['authGroup'],
                options: this._newMdoService.departments,
                valueProp: 'authGroupId',
                labelProp: 'text',
            },
        };
    }

    get organizationFieldDef(): FormlyFieldConfig {
        return {
            className: 'flex-1',
            type: 'select',
            key: 'organization',
            props: {
                label: this._translocoContent()['organization'],
                options: [this._processService._organisation()],
                valueProp: 'id',
                labelProp: 'title',
            },
        };
    }

    // validation errors for the form fields
    private getRequiredValidation(message: string): any {
        return {
            messages: {
                required: message,
                pattern: this._errors()['pattern'],
            },
        };
    }
}
